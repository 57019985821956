import { FC, lazy, Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { getUser } from '../services/UserService'
import { ToastContainer } from 'react-toastify'
import { UserModel } from '../services/models/UserModel'

const PrivateRoutes = () => {
	const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
	const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
	const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
	const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
	const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
	const BuildingPage = lazy(() => import('../pages/buildings/building/BuildingPage'))
	const DashboardWrapper = lazy(() => import('../pages/home/Home'))
	const BuildingsPage = lazy(() => import('../pages/buildings/Buildings'))
	const Measure = lazy(() => import('../pages/measure/Measure'))
	const Measurepack = lazy(() => import('../pages/measurepack/Measurepack'))
	const Measures = lazy(() => import('../pages/measures/Measures'))
	const Portfolio = lazy(() => import('../pages/brands/Portfolio'))
	const Configure = lazy(() => import('../pages/settings/Configure'))
	const AdminPage = lazy(() => import('../pages/admin/AdminPage'))
	const ClientAdmin = lazy(() => import('../pages/superuser/ClientAdmin'))
	const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'))
	const Reports = lazy(() => import('../pages/home/sub/Reports'))
	const Table = lazy(() => import('../pages/home/sub/Table'))
	const SupportPage = lazy(() => import('../pages/support-center/SupportPage'))
	const AccountSettings = lazy(() => import('../pages/account/Settings'))
	const Pricing = lazy(() => import('../pages/pricing/Pricing'))

	const [user, setUser] = useState<UserModel | null>(null)

	const fetchUser = async () => {
		const user: UserModel = await getUser()
		if (user) setUser(user)
	}

	useEffect(() => {
		fetchUser()
	}, [])

	return (
		<Routes>
			<Route element={<MasterLayout user={user!} />}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path="login" element={<Navigate to="/dashboard" />} />
				<Route
					path="/dashboard"
					element={
						user && user.role === 'Localuser' ? (
							<Navigate to="/sites" />
						) : (
							<SuspensedView>
								<DashboardWrapper user={user!} />
							</SuspensedView>
						)
					}
				/>
				<Route path="builder" element={<BuilderPageWrapper />} />
				<Route path="menu-test" element={<MenuTestPage />} />
				{/* Lazy Modules */}
				{/* create path for building page, it's a page per /building/{id} */}
				<Route
					path="sites/"
					element={
						<SuspensedView>
							<BuildingsPage user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="measures"
					element={
						<SuspensedView>
							<Measures user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="user/:id/*"
					element={
						<SuspensedView>
							<ProfilePage tokenuser={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="brands"
					element={
						<SuspensedView>
							<Portfolio user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="dashboard/status"
					element={
						<SuspensedView>
							<Reports user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="dashboard/table"
					element={
						<SuspensedView>
							<Table user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="configure"
					element={
						<SuspensedView>
							<Configure />
						</SuspensedView>
					}
				/>
				<Route
					path="pricing"
					element={
						<SuspensedView>
							<Pricing user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="panel"
					element={
						<SuspensedView>
							<ClientAdmin user={user!} />
						</SuspensedView>
					}
				/>
				<Route path="admin/*"
					element={
						<SuspensedView>
							{user && user.role === 'Admin' ? (
								<AdminPage user={user!} />
							) : (
								'Access denied...'
							)}
						</SuspensedView>
					}
				/>
				<Route
					path="/account/settings"
					element={
						<SuspensedView>
							<AccountSettings />
						</SuspensedView>
					}
				/>
				<Route
					path="/measure/:building_id/:id"
					element={
						<SuspensedView>
							<Measure user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="/measure-pack/:building_id/:id"
					element={
						<SuspensedView>
							<Measurepack user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="/sites/:id/*"
					element={
						<SuspensedView>
							<BuildingPage user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="support-center/*"
					element={
						<SuspensedView>
							<SupportPage user={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="crafted/pages/profile/*"
					element={
						<SuspensedView>
							<ProfilePage tokenuser={user!} />
						</SuspensedView>
					}
				/>
				<Route
					path="crafted/pages/wizards/*"
					element={
						<SuspensedView>
							<WizardsPage />
						</SuspensedView>
					}
				/>
				<Route
					path="crafted/widgets/*"
					element={
						<SuspensedView>
							<WidgetsPage />
						</SuspensedView>
					}
				/>
				<Route
					path="crafted/account/*"
					element={
						<SuspensedView>
							<AccountPage />
						</SuspensedView>
					}
				/>
				<Route
					path="apps/chat/*"
					element={
						<SuspensedView>
							<ChatPage />
						</SuspensedView>
					}
				/>
				<Route
					path="apps/user-management/*"
					element={
						<SuspensedView>
							<UsersPage />
						</SuspensedView>
					}
				/>
				{/* Page Not Found */}
				<Route path="*" element={<Navigate to="/error/404" />} />
			</Route>
		</Routes>
	)
}



const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})

	return (
		<Suspense fallback={<TopBarProgress />}>
			{children}
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme={
					localStorage &&
						localStorage.getItem('kt_theme_mode_menu') === 'dark'
						? 'dark'
						: 'light'
				}
			/>
		</Suspense>
	)
}

export { PrivateRoutes }
